/* 06.Breadcrumb */
.breadcrumb-container {
  .breadcrumb {
    @include respond-below(md) {
      padding: 0;
    }
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem;
  padding-left: 0;
  padding-top: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}

.breadcrumb-item, .breadcrumb-item a{
  font-size: 0.7rem;
}