
/* 10.Menu*/
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: $navbar-height;
  z-index: 4;
  height: calc(100% - #{$navbar-height});
  transition: border-radius $menu-collapse-time;

  .main-menu {
    width: $main-menu-width;
    height: calc(100% - #{$navbar-height});
    background: theme('colors.foreground-color');
    z-index: 99;
    position: fixed;
    transition: transform $menu-collapse-time;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 0;

    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%;

      .ps__thumb-y {
        right: 0;
      }
    }

    &.main-hidden {
      transform: translateX(-$main-menu-width);
    }

    ul li {
      position: relative;

      span {
        text-align: center;
        padding: 0 10px;
        line-height: 1.1rem;
      }

      a {
        height: $main-menu-item-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid theme('colors.separator-color-l');
        color: theme('colors.darker');
        transition: color $menu-collapse-time;
        transition: background $menu-collapse-time;

        &:hover,
        &:focus {
          color: theme('colors.primary');
        }
      }

      i {
        font-size: 24px;
        line-height: 42px;
      }

      &.active a {
        color: theme('colors.primary');
      }

      &.active:after {
        content: " ";
        background: theme('colors.primary');
        border-radius: 10px;
        position: absolute;
        width: 6px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }

      &:last-of-type {
        a {
          border-bottom: initial;
        }
      }
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      width: $main-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});

      ul li i {
        font-size: 28px;
        line-height: 38px;
      }

      ul li a {
        height: $main-menu-item-height-mobile;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }

  .sub-menu {
    width: $sub-menu-width;
    background: theme('colors.foreground-color');
    z-index: 2;
    position: fixed;
    left: $main-menu-width;
    border-left: 1px solid theme('colors.separator-color-l');
    @include depth(2);
    transition: transform $menu-collapse-time;
    height: calc(100% - #{$navbar-height});
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;

    .scroll {
      margin-right: unset;
      padding-right: unset;
      height: 100%;

      .ps__thumb-y {
        right: 0;
      }
    }

    ul:not(.block) {
      display: none;
    }

    ul {
      li {
        margin-bottom: 0;
        position: relative;

        a {
          font-size: 13px;
          display: block;
          padding: 6px 0;
          margin-bottom: 0;
        }

        i {
          font-size: 1.3em;
          margin-right: 10px;
          color: theme('colors.gray');
          vertical-align: middle;
          display: inline-block;
        }

        &.active i,
        &.active a {
          color: theme('colors.primary');
        }

        &.active:after {
          content: " ";
          background: theme('colors.primary');
          border-radius: 10px;
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          left: -16px;
        }

        span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block;
        }
      }

      li.has-sub-item {
        &.active:after {
          content: " ";
          background: initial;
        }
      }

      ul.third-level-menu {
        display: block !important;
        li {
          a,
          i {
            color: theme('colors.darker');
          }
          a:hover,
          &.active i,
          &.active a {
            color: theme('colors.primary');
          }

          &.active:after {
            left: -22px;
          }
        }
      }

      .rotate-arrow-icon {
        i {
          transition: transform $animation-time-short;
          transform: rotate(0);
        }

        &.collapsed {
          i {
            transform: rotate(-90deg);
          }
        }
      }
    }

    @include respond-below(xl) {
      left: $main-menu-width-lg;
      width: $sub-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      left: $main-menu-width-md;
      width: $sub-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      left: $main-menu-width-xs;
      width: $sub-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});
    }
  }

  @include respond-below(xl) {
    padding-top: $navbar-height-lg;
    height: calc(100% - #{$navbar-height-lg});
  }

  @include respond-below(lg) {
    padding-top: $navbar-height-md;
    height: calc(100% - #{$navbar-height-md});
  }

  @include respond-below(sm) {
    padding-top: $navbar-height-xs;
    height: calc(100% - #{$navbar-height-xs});
    @include depth(1);
  }
}

//Menu Config Main Menu Sub Menu

.sub-hidden .sub-menu,
.menu-sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$sub-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$sub-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$sub-menu-width-xs);
  }
}

.main-hidden .main-menu,
.menu-hidden .main-menu {
  transform: translateX(-$main-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$main-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$main-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$main-menu-width-xs);
  }
}

.main-hidden.sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width - $main-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$sub-menu-width-lg - $main-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$sub-menu-width-md - $main-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
  }
}

//Mobile
.menu-mobile {
  .main-menu {
    transform: translateX(-$main-menu-width-xs);
  }

  .sub-menu {
    transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs - 50);
  }
}

//Show Temporary
.main-show-temporary {
  .main-menu {
    transform: translateX(0);
  }

  .sub-menu {
    transform: translateX(-$sub-menu-width);
  }

  @include respond-below(xl) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-lg);
    }
  }

  @include respond-below(lg) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-md);
    }
  }

  @include respond-below(sm) {
    .sub-menu {
      transform: translateX(-$sub-menu-width-xs);
    }
  }
}

.sub-show-temporary,
.menu-mobile.sub-show-temporary {
  .sub-menu {
    transform: translateX(0);
  }
}

/* 15.Main*/
main {
  margin-left: $sub-menu-width + $main-menu-width + $main-margin;
  padding-top: $navbar-height + $main-margin - 25;
  margin-right: $main-margin;
  margin-bottom: $main-margin - 20;
  transition: margin-left $menu-collapse-time;

  &.sub-hidden {
    margin-left: $main-menu-width + $main-margin;
  }

  &.main-hidden {
    margin-left: $main-margin;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include respond-below(xl) {
    margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
    margin-right: $main-margin-lg;
    margin-top: $main-margin-lg + $navbar-height-lg - 10;
    margin-bottom: 30px;
  }

  @include respond-below(lg) {
    margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
    margin-right: $main-margin-md;
    margin-top: $main-margin-md + $navbar-height-md - 10;
    margin-bottom: 20px;
  }

  @include respond-below(sm) {
    margin-left: $main-margin-xs !important;
    margin-right: $main-margin-xs !important;
    margin-top: $main-margin-xs + $navbar-height-xs !important;
    margin-bottom: 0;
  }

  @include respond-below(xs) {
    margin-bottom: 0;
  }
}

//Menu Config Main

.sub-hidden main,
.menu-sub-hidden main,
.menu-hidden main {
  margin-left: $main-menu-width + $main-margin;
}

.main-hidden main,
.menu-hidden main {
  margin-left: $main-margin;
}

@include respond-below(xl) {
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-left: $main-menu-width-lg + $main-margin-lg;
  }

  .main-hidden main,
  .menu-hidden main {
    margin-left: $main-margin-lg;
  }
}

@include respond-below(lg) {
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-left: $main-menu-width-md + $main-margin-md;
  }

  .main-hidden main,
  .menu-hidden main {
    margin-left: $main-margin-md;
  }
}


/* 36.Context Menu */
.react-contextmenu {
  box-shadow: initial;
  border-radius: $border-radius;
  background: theme('colors.input-bg');
  border: 1px solid theme('colors.darker-x-muted');
  padding: 0.5rem 0;
  opacity: 0;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: theme('colors.input-bg');
  color: theme('colors.darker');

  cursor: pointer;

  span {
    font-family: "Karla", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px;
    display: inline-block;
  }

  &:hover {
    color: theme('colors.darker');
    text-decoration: none;
    background-color: theme('colors.bg-color');
  }
}

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: theme('colors.darker');
  text-decoration: none;
  background-color: theme('colors.bg-color');
}

.card.react-contextmenu--visible,
.card.active {
  @include depth(2);
}
