/* 02.Buttons */

.btn-primary {
  border: none;
}

button {
  @apply text-darker;
  outline: initial !important;
}

.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px;
}

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center;
}

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px;
}

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px;
}

.btn {
 
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
  transition: background-color box-shadow 0.1s linear;
  font-weight: 400!important;

  &:not(.no-radius) {
    border-radius: 50px;
  }
}

.btn-primary {
  @apply shadow-md;
  text-transform: uppercase;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;

  &:hover,
  &:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-empty {
  background: transparent !important;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-sm > .btn {
  border-radius: 50px;
}

.btn.default {
  border-radius: $border-radius;
}

.btn-primary {
  @apply bg-primary border-primary text-btn-text-l;

  &:hover {
    @apply bg-primary-d border-primary-d text-btn-text-l;
  }
}

.check-button {
  cursor: default !important;
}

.check-button.btn-primary {
  @apply bg-primary border-primary;
  opacity: 1;
}

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px;
}

.dropdown-menu {
  font-size: 0.8rem;
  @apply bg-input-bg rounded border-border-l;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  @apply text-darker;
}

.dropdown-item:hover,
.dropdown-item:focus {
  @apply text-darker bg-bg-color;
}

.dropdown-item.active,
.dropdown-item:active {
  @apply text-btn-text-l bg-primary;
  text-decoration: none;
}

.dropdown-divider {
  @apply border-separator-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  @apply text-btn-text-l border-primary-xd bg-primary-xd;
}

.btn-secondary {
  @apply text-btn-text-l bg-secondary border-secondary;

  &:hover {
    @apply text-btn-text-l bg-primary-d border-primary-d;
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  @apply text-btn-text-l border-secondary-xd bg-secondary-xd;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right, theme('colors.primary'), theme('colors.primary-xd'));

  &:hover {
    background: linear-gradient(to right, theme('colors.primary'), theme('colors.primary-d'));
  }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right, theme('colors.primary'), theme('colors.primary-xd'));
}

.btn-secondary-gradient {
  background: linear-gradient(to right, theme('colors.secondary'), theme('colors.secondary-xd'));

  &:hover {
    background: linear-gradient(to right, theme('colors.secondary'), theme('colors.secondary-d'));
  }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right, theme('colors.secondary'), theme('colors.secondary-xd'));
}

.btn-warning {
  background-color: $warning-color;
  border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: theme('colors.btn-text-l');

  &:hover {
    color: theme('colors.btn-text-l');
  }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
  &:hover {
    color: theme('colors.foreground-color');
  }
}

.btn-light {
  color: theme('colors.btn-bg-d');
  background-color: theme('colors.btn-bg-l');
  border-color: theme('colors.btn-bg-l');

  &:hover {
    color: theme('colors.btn-bg-d');
    background-color: theme('colors.btn-bg-md');
    border-color: theme('colors.btn-bg-md');
  }
}

.btn-dark {
  color: theme('colors.btn-bg-l');
  background-color: theme('colors.btn-bg-d');
  border-color: theme('colors.btn-bg-d');

  &:hover {
    color: theme('colors.btn-bg-l');
    background-color: theme('colors.btn-bg-xd');
    border-color: theme('colors.btn-bg-xd');
  }
}

.btn-outline-dark {
  color: theme('colors.btn-bg-d');
  border-color: theme('colors.btn-bg-d');

  &:hover {
    color: theme('colors.foreground-color');
    background-color: theme('colors.btn-bg-d');
    border-color: theme('colors.btn-bg-d');
  }
}

.btn-outline-white {
  color: theme('colors.btn-text-l');
  border-color: theme('colors.btn-text-l');
  background-color: initial;

  &:hover {
    color: theme('colors.primary');
    background-color: theme('colors.btn-text-l');
  }
}

.btn-outline-light {
  color: theme('colors.btn-bg-l');
  border-color: theme('colors.btn-bg-l');

  &:hover {
    color: theme('colors.foreground-color');
    background-color: theme('colors.btn-bg-l');
    border-color: theme('colors.btn-bg-l');
  }
}

.btn-outline-primary {
  color: theme('colors.primary');
  border-color: theme('colors.primary');
  border-width: 1px;
  font-weight: 600;

  &:hover {
    color: theme('colors.foreground-color');
    background-color: theme('colors.primary');
    border-color: theme('colors.primary');
  }
}

.btn-outline-theme-3 {
  background: unset;
  color: theme('colors.tertiary');
  border-color: theme('colors.tertiary');

  &:hover {
    background-color: theme('colors.tertiary');
    border-color: theme('colors.tertiary');
    color: theme('colors.foreground-color');
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: theme('colors.primary');
  border-color: theme('colors.primary');
  color: theme('colors.foreground-color');
}

.btn-outline-secondary {
  color: theme('colors.secondary');
  border-color: theme('colors.secondary');

  &:hover {
    background-color: theme('colors.secondary');
    border-color: theme('colors.secondary');
    color: theme('colors.foreground-color');
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: theme('colors.secondary');
  border-color: theme('colors.secondary');
  color: theme('colors.foreground-color');
}

.btn-header-light {
  color: theme('colors.separator-color');
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: theme('colors.separator-color');
  }
}

.btn-header-primary {
  color: theme('colors.primary');
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: theme('colors.primary');
  }
}

.btn-header-secondary {
  color: theme('colors.secondary');
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: theme('colors.secondary');
  }
}

.btn-header-primary-light {
  color: theme('colors.tertiary');
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: theme('colors.tertiary');
  }
}

.btn-xl,
.btn-group-xl > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: theme('colors.primary');
  border-color: theme('colors.primary');
  color: theme('colors.foreground-color');
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: theme('colors.secondary');
  border-color: theme('colors.secondary');
  color: theme('colors.foreground-color');
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-link {
  color: theme('colors.primary');
}

.btn-link:hover {
  color: theme('colors.primary-muted');
  text-decoration: underline;
}

.white-underline-link {
  color: theme('colors.btn-text-l');
  text-decoration: underline;

  &:hover,
  &:active {
    color: theme('colors.btn-text-l');
    text-decoration: initial;
  }
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: theme('colors.btn-text-l');
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }

  &.btn-primary:disabled {
    opacity: 1;
    background: theme('colors.primary-xd');
    border-color: theme('colors.primary-xd');
  }

  &.btn-secondary:disabled {
    opacity: 1;
    border-color: theme('colors.secondary-xd');
  }
}

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;

  &.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
  }

  &.small-icon {
    font-size: 12px;
    line-height: 32px;
  }
}

.top-right-button-single {
  width: unset;

  @include respond-below(md) {
    width: calc(100%);
  }
}

.top-right-button-container {
  float: right;
  position: relative;
  z-index: 999;

  @include respond-below(xs) {
    float: initial;
    display: flex;
    justify-content: space-between;
  }

  @include respond-below(lg) {
    position: fixed;
    right: 15px;
    bottom: 72px
   }

  .top-right-button {
    @include respond-below(xs) {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }

  .dropdown {
    @include respond-below(xs) {
      display: flex;
      flex-grow: 1;
      margin-right: 5px;
    }
  }
}