/* 00.Variables and Imports */

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;


$grid-breakpoints: (
  xxl: 1450px,
);

$main-menu-item-height: 110px;
$main-menu-width: 90px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 70px;
$navbar-height-xs: 55px;
$navbar-height-md: 55px;
$navbar-height-lg: 70px;

$navbar-footer-height: 60px;

$main-margin: 25px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-purple: #922c88;
$theme-color-blue: #145388;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

/* 01.Base */
html {
  width: 100%;
  height: 100%;
  @apply bg-bg-color;
}
