
/* 09.Navbar*/
.navbar {
  background: theme('colors.foreground-color');
  height: $navbar-height;
  padding: 0.7rem 0;
  @include depth(1);
  flex-wrap: nowrap;

  @include respond-below(md) {
  // box-shadow: none;
  }

  .list-thumbnail-letters.small {
    width: 48px;
    height: 48px;
    margin-left: 0.5rem;
  }

  #notificationDropdown {
    width: 260px;
    padding: 1.5rem !important;
    height: 280px;
    right: 15px;
  }

  #iconMenuDropdown {
    width: 240px;
    padding: 1.5rem !important;
    height: 280px;
  }

  .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.7rem;
    color: theme('colors.darker-muted');

    i {
      font-size: 28px;
      line-height: 42px;
    }

    span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px;
    }

    &:hover,
    &:focus {
      color: theme('colors.primary');
    }
  }

  .main-menu--button {
    width: $main-menu-width;
  
    @include respond-below(sm) {
      width: 60px;
    }
  }

  .menu-button-mobile {
    color: theme('colors.gray');
    text-align: center;
    margin-left: $main-margin-xs;
    z-index: 3;
    svg {
      height: 12px;
      fill: theme('colors.darker');
    }

    @include respond-below(sm) {
      width: 20px;
    }
  }

  .menu-button {
    color: theme('colors.gray');
    text-align: center;
    
    svg {
      height: 12px;
    }

    .main {
      fill: theme('colors.darker');
      transition: fill $menu-collapse-time;
      width: 10px;
    }

    .sub {
      fill: theme('colors.darker');
      transition: fill $menu-collapse-time;
    }

    &:hover {
      color: theme('colors.primary');
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
    }

    @include respond-below(sm) {
      width: 60px;
    }
  }

  .navbar-left {
    flex-basis: 40%;

    &.isNative {
      .search {
        left: 5px;
      }
    }

    &.back {
      .search {
        left: 30px;
      }
    }
  }

  .navbar-right {
    flex-basis: 40%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  .navbar-logo-mobile {
    position: absolute;
    margin: 0 auto;
    width: 7rem;
    height: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    span {
      width: 100%;
     // background: url($logoPath) no-repeat;

     svg {
      max-height: 45px;
      margin-top: -5px;
     }
      height: 100%;
    }
  }

  .navbar-logo {
    width: 110px;
    height: 40px;
    margin: 0 15px 0 0;
    margin-right: 3rem;

    @include respond-below(lg) {
      display: none!important;
    }

    .logo {
      width: 100%;
      height: 100%;

      svg {
        height: 58px;
        margin-top: -9px;
        margin-right: 0.5rem;
      }
      background-position: center center;
    }

    .logo-mobile {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: contain;
    }

    @include respond-below(sm) {
      width: 80px;
    }

    @include respond-below(xxs) {
      width: 20px;
    }
  }

  .language-button {
    background: theme('colors.bg-color');
    border: initial;
    font-size: 0.8rem;
    color: theme('colors.gray');
    padding: 0.6rem 1rem;

    @include respond-below(xs) {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  .search {
    //position: relative;
    width: 400px;
    border-radius: 20px;
    margin-left: 10px;
    transition: all 0.4s ease-in-out;

    #search {
      z-index: 4;
      background-color: white;
      min-width: 230px;
    }

    fieldset {
      margin-top: 6px;
    }

    .search-back, .search-expand {
      display:none;
    }

    input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 1.75;
      font-size: 1rem;
      width: 93%;
      color: theme('colors.darker');
    }

    button {
      border-radius: 0 1rem 1rem 0;
      border-color: transparent!important;
      background-color:transparent!important;
      color: theme('colors.primary')!important;
      transition: background 0.4s ease-in-out;

    }


    .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: theme('colors.separator-color');
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -3px;
      right: 3px;
      text-align: center;
      cursor: pointer;


      @include respond-below(md) {
        bottom: -4px!important;
        
      }

      &:hover {
        color: theme('colors.primary');
      }
    }

    @include respond-below(lg) {
      width: 350px;
      input {
        width: 100%;
      }
    }

    @include respond-below(md) {
      width: 350px;
      input {
        width: 100%;
      }
      svg {
        font-size: 1.2rem;
      }

      #search {
        .MuiInputBase-root {
          height: 48px;
          background-color: white;
        }
      }

    }

    @include respond-above(sm) {
      .search-back {
        display:none;
      }
    }

    @include respond-below(sm) {

      #search {

        .MuiAutocomplete-root {
          &.Mui-focused {
            width: 100vw;
            margin-left: 6%;

            fieldset {
              border-color: transparent;
            }
          }
        }

        .MuiInputBase-root {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

      }

      width: 250px;
      display: flex;
      justify-content: center;
      margin: 0;
      flex: 1;
      position: absolute;
      left: 50px;

      

      .clear-search {
        top: 0.8rem;
      }

      .search-expand {
        transform: scale(0);
        transition: all 0.4s ease-in-out;
        display: block;
        width: 0;
      }
  
      .search-back {
        transform: scale(1);
        transition: all 0.4s ease-in-out;
        padding: 0.8rem 0.2rem 0.8rem 0.8rem;
        width: 0;
        display: block;
        margin-top: 0.3rem;
        margin-right: 0.5rem;
        width: 40px;
        border-radius: 50%;
        height: 40px;

        svg {
          margin: 0;
        }
      }

      &.hide-back {
        .search-back {
          display: none;
        }
      }

      &.collapsed {
        max-width: 50px;

        .search-back {
          transform: scale(0);
          padding: 0;
          width: 0;
        }

        .search-ctn {
          width: 0;
        }

        .clear-search {
          display: none;
        }

        .search-expand {
          transform: scale(1);
          padding: 0.8rem 0.8rem;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: -15px;
          margin-top:  2px;

          svg {
            margin: 0;
          }
        }

        #search {
          display: none;
          z-index: 2;
        }
      }

    }
  }

  
  .isNative {
    #search {
        input {
          margin-left: 50px!important;
        }
    }
  }


  .header-icons {
    margin-right: 1rem;

    @include respond-below(xs) {
      margin-right: 0;
    }
  }

  .header-icon {
    font-size: 16px;
    color: theme('colors.gray');
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial;

    @include respond-below(xs) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    &:hover {
      color: theme('colors.primary');
    }

    &#fullScreenButton i:last-of-type {
      display: none;
    }

    &.notificationButton {
      .count {
        font-size: 9px;
        color: theme('colors.primary');
        border: 1px solid theme('colors.primary');
        border-radius: 10px;
        position: absolute;
        width: 18px;
        height: 15px;
        text-align: center;
        font-weight: 700;
        top: 2px;
        right: 2px;
        line-height: 14px;

        @include respond-below(xs) {
          right: -1px;
        }
      }
    }
  }

  .user {
    color: theme('colors.darker-l');
    position: relative;

    button {
      display: flex;
      align-items: center;
      p {
        color: theme('colors.darker');
      }
  
    }

   
    .profile-pic {
      margin-left: 10px;
      border-radius: 30px;
      width: 40px;
      height: 40px;
      overflow: hidden;
      background-position: top center;
      background-size: cover;
      img {
        width: 40px;
      }
    }

    @include respond-below(md) {
      .name {
        display: none;
      }
    }

    @include respond-below(sm) {
      img {
        width: 30px;
      }

      margin-left: initial;

      &:after {
        font-size: 11px;
        width: 14px;
        height: 14px;
        bottom: -3px;
        right: -3px;
      }
    }
  }

  @include respond-below(xl) {
    height: $navbar-height-lg;
  }

  @include respond-below(lg) {
    height: $navbar-height-md;
    padding: 0.7rem 0 0.7rem 0;
  }

  @include respond-below(sm) {
    height: $navbar-height-xs;
    padding: 10px 0;
  }
}

.sub-hidden .menu-button,
.menu-sub-hidden .menu-button {
  .sub {
    fill: theme('colors.gray');
  }
}

.main-hidden .menu-button,
.menu-hidden .menu-button {
  .main,
  .sub {
    fill: theme('colors.gray');
  }
}


  /*Navbar */
  .navbar {
    .navbar-right {
      text-align: left;
    }

    .menu-button-mobile {
      margin-right: 15px;
    }

    .menu-button {
      text-align: center;
    }

    .icon-menu-item {
      text-align: center;

      i {
        text-align: center;
      }

      span {
        text-align: center;
      }
    }

    .search {
      .search-icon {
        right: initial;
        left: 5px;
      }

      @include respond-below(sm) {
        margin-right: 0.6rem;
        margin-left: initial;

        input {
          display: none;
        }


        &.mobile-view {
          margin-left: initial;
          margin-right: 0px;

    
          input {
            display: block;
            width: 100%;
            height: 60px;
            padding-left: 15px;
          }

          span {
            left: 15px;
            right: initial;
          }
        }
      }
    }

    .navbar-right {
      text-align: left;

      .user {


        img {
          margin-right: 10px;
          margin-left: initial;
        }
      }

      .header-icons {
        margin-right: initial;
        margin-left: 0.5rem;
        @include respond-below(xs) {
          margin-left: 0;
        }
      }

      #notificationButton {
        .count {
          text-align: center;

          @include respond-below(xs) {
            left: -1px;
          }
        }
      }

      #userDropdown,
      #userDropdown * {
        text-align: left;
      }
    }
  }

  .heading-icon {
    margin-right: initial;
    margin-left: 5px;
  }

  .survey-app .answers input {
    padding-right: 0.75rem;
    padding-left: 70px;
  }

  .input-icons {
    left: 0;
    right: initial;
  }

  .simple-icon-arrow-left:before {
    content: "\e606";
  }

  .simple-icon-arrow-right:before {
    content: "\e605";
  }

  //Menu Config Main Menu Sub Menu
  .sub-hidden .sub-menu,
  .menu-sub-hidden .sub-menu,
  .menu-hidden .sub-menu {
    transform: translateX($sub-menu-width);

    @include respond-below(xl) {
      transform: translateX($sub-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX($sub-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX($sub-menu-width-xs);
    }
  }

  .main-hidden .main-menu,
  .menu-hidden .main-menu {
    transform: translateX($main-menu-width);

    @include respond-below(xl) {
      transform: translateX($main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX($main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX($main-menu-width-xs);
    }
  }

  .main-hidden.sub-hidden .sub-menu,
  .menu-hidden .sub-menu {
    transform: translateX($sub-menu-width + $main-menu-width);

    @include respond-below(xl) {
      transform: translateX($sub-menu-width-lg + $main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX($sub-menu-width-md + $main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX($sub-menu-width-xs + $main-menu-width-xs);
    }
  }

  .menu-main-hidden {
    .main-menu {
      width: 0;
    }

    .sub-menu {
      right: 0;
    }
  }

  //Mobile
  .menu-mobile {
    .main-menu {
      transform: translateX($main-menu-width-xs);
    }

    .sub-menu {
      transform: translateX($sub-menu-width-xs + $main-menu-width-xs + 50);
    }
  }

  //Show Temporary
  .main-show-temporary {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX($sub-menu-width);
    }

    @include respond-below(xl) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX($sub-menu-width-lg);
      }
    }

    @include respond-below(lg) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX($sub-menu-width-md);
      }
    }

    @include respond-below(sm) {
      .sub-menu {
        transform: translateX($sub-menu-width-xs);
      }
    }
  }

  .sub-show-temporary,
  .menu-mobile.sub-show-temporary,
  .menu-main-hidden.menu-mobile.main-show-temporary {
    .sub-menu {
      transform: translateX(0);
    }
  }

  
  /*Chat Input Container*/
  .chat-input-container {
    padding-right: $main-menu-width + $main-margin + $sub-menu-width;
    padding-left: $app-menu-width + $main-margin;

    @include respond-below(xl) {
      padding-right: $main-menu-width-lg + $main-margin-lg + $sub-menu-width-lg;
      padding-left: $app-menu-width + $main-margin-lg;
    }

    @include respond-below(lg) {
      padding-right: $main-menu-width-md + $main-margin-md + $sub-menu-width-md;
      padding-left: $main-margin-md;
    }

    @include respond-below(sm) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .menu-sub-hidden,
  .sub-hidden {
    .chat-input-container {
      padding-right: $main-menu-width + $main-margin;

      @include respond-below(xl) {
        padding-right: $main-menu-width-lg + $main-margin-lg;
      }

      @include respond-below(lg) {
        padding-right: $main-menu-width-md + $main-margin-md;
      }

      @include respond-below(sm) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .menu-main-hidden,
  .menu-hidden,
  .main-hidden {
    .chat-input-container {
      padding-right: $main-margin;

      @include respond-below(xl) {
        padding-right: $main-margin-lg;
      }

      @include respond-below(lg) {
        padding-right: $main-margin-md;
      }

      @include respond-below(sm) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  //Menu Config Main
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    // margin-right: $main-menu-width + $main-margin;
  }

  .main-hidden main,
  .menu-hidden main {
    // margin-right: $main-margin;
  }

  .menu-main-hidden {
    main {
      // margin-right: $main-margin + $sub-menu-width;
    }
    &.menu-hidden {
      main {
        // margin-right: $main-margin;
      }
    }
  }

  @include respond-below(xl) {
    .sub-hidden main,
    .menu-sub-hidden main,
    .menu-hidden main {
      // margin-right: $main-menu-width-lg + $main-margin-lg;
    }

    .main-hidden main,
    .menu-hidden main {
      // margin-right: $main-margin-lg;
    }

    .menu-main-hidden {
      main {
        // margin-right: $main-margin-lg + $sub-menu-width-lg;
      }
      &.menu-hidden {
        main {
          // margin-right: $main-margin-lg;
        }
      }
    }
  }

  @include respond-below(lg) {
    .sub-hidden main,
    .menu-sub-hidden main,
    .menu-hidden main {
      // margin-right: $main-menu-width-md + $main-margin-md;
    }

    .main-hidden main,
    .menu-hidden main {
      // margin-right: $main-margin-md;
    }

    .menu-main-hidden {
      main {
        // margin-right: $main-margin-md + $sub-menu-width-md;
      }
      &.menu-hidden {
        main {
          // margin-right: $main-margin-md;
        }
      }
    }
  }

  .theme-colors {
    transform: translate(-280px, -50%);
    left: 0;
    right: initial;

    .theme-button {
      left: 278px;
      box-shadow: 3px 0 5px rgba(0, 0, 0, 0.04);
    }

    &.shown {
      transform: translate(0, -50%);
    }
  }

  // // Rounded
  // &.rounded {
  //   .app-menu {
  //     border-top-right-radius: $border-radius-rounded;
  //     border-bottom-right-radius: $border-radius-rounded;
  //   }

  //   .sub-menu {
  //     border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
  //   }

  //   .auth-card .image-side {
  //     border-top-left-radius: $border-radius-rounded;
  //     border-bottom-left-radius: $border-radius-rounded;
  //   }

  //   .card-img,
  //   .card-img-fluid,
  //   .card-img-overlay {
  //     border-radius: $border-radius-rounded;
  //   }

  //   .sub-hidden .main-menu,
  //   .menu-sub-hidden .main-menu {
  //    // border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
  //   }

  //   .sub-show-temporary .main-menu {
  //     border-radius: initial;
  //   }

  //   .card-img-left {
  //     border-top-right-radius: $border-radius-rounded !important;
  //     border-bottom-right-radius: $border-radius-rounded !important;
  //     border-top-left-radius: initial !important;
  //     border-bottom-left-radius: initial !important;
  //   }

  //   .card-img-right {
  //     border-top-left-radius: $border-radius-rounded !important;
  //     border-bottom-left-radius: $border-radius-rounded !important;
  //     border-top-right-radius: initial !important;
  //     border-bottom-right-radius: initial !important;
  //   }

  //   .card.auth-card {
  //     border-top-left-radius: $border-radius-rounded;
  //     border-bottom-left-radius: $border-radius-rounded;
  //     border-top-right-radius: $border-radius-rounded + 0.5rem;
  //     border-bottom-right-radius: $border-radius-rounded + 0.5rem;
  //   }

  //   .auth-card .image-side {
  //     border-top-left-radius: initial;
  //     border-bottom-left-radius: initial;
  //     border-top-right-radius: 0.75rem;
  //     border-bottom-right-radius: 0.75rem;
  //   }
  // }

