
/* 44.Rtl */
.rtl {
  /*Basic*/
  direction: rtl;

  .list-unstyled {
    padding-right: 0;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: initial;
    padding-right: 0.5rem;
    &::before {
      padding-left: 0.5rem;
      padding-right: initial;
    }
  }

  .dropdown-menu:not(.datepicker-dropdown) {
    right: initial !important;
  }

  .dropdown-menu-right {
    right: initial !important;
    left: 0 !important;
  }

  .dropdown-menu-left {
    right: 0 !important;
    left: initial !important;
  }

  .btn-group > .btn:first-child {
    margin-left: -1px;
  }

  .top-right-button-container {
    float: left;
    @include respond-below(xs) {
      float: initial;
    }
 
    .top-right-button {
      @include respond-below(xs) {
        margin-left: 5px;
      }
    }
  }

  .comment-contaiener {
    input {
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;
      border-top-right-radius: 50px !important;
      border-bottom-right-radius: 50px !important;
      padding-left: 0.75rem;
      padding-right: 20px;
    }
  }

  .glide__arrow.glide__arrow--left {
    .simple-icon-arrow-left:before {
      content: "\e605";
    }
  }

  .glide__arrow.glide__arrow--right {
    .simple-icon-arrow-right:before {
      content: "\e606";
    }
  }

  .search-sm::after {
    left: 0;
    right: initial;
    text-align: initial;
    top: 3px;
  }

  .dropdown-toggle-split:after,
  .dropright .dropdown-toggle-split:after,
  .dropup .dropdown-toggle-split:after {
    margin-left: 2px;
    margin-right: 2px;
  }

  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-radius: $border-radius;
  }

  .btn-group.dropleft .dropdown-toggle-split {
    border-radius: $border-radius;
  }

  .fc-toolbar.fc-header-toolbar .btn-group .fc-next-button.btn,
  .fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button.btn {
    border-radius: 40px !important;
  }

  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .input-group-text {
    margin-left: 0px;
    margin-right: -1px;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: $border-radius;
  }

  .input-group-text {
    border-radius: $border-radius !important;
  }

  .input-group-prepend {
    margin-right: 0;
    margin-left: -1px;
  }

  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-radius: 50px;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 50px;
  }

  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .input-group-prepend:first-child > .btn:not(:first-child) {
    border-radius: 0 !important;
  }

  .input-group > .input-group-append > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 0;
    padding-left: 30px;
  }

  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: initial;
    left: 2em;
  }

  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: initial;
    left: 1.5em;
  }

  .ck-editor {
    direction: rtl;
  }

  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-radius: initial;
  }

  .btn-group > .btn:first-child {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .btn-group > .btn:last-of-type {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: initial;
    left: 0;
  }

  .datepicker-dropdown {
    right: initial;
  }

  .card .card-header .card-icon {
    left: 10px;
    right: initial;
  }

  .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: initial;
  }

  .pagination {
    .page-link.prev {
      i::before {
        content: "\e606";
      }
    }

    .page-link.next {
      i::before {
        content: "\e605";
      }
    }

    .page-link.first {
      i::before {
        content: "\e074";
      }
    }

    .page-link.last {
      i::before {
        content: "\e06f";
      }
    }
  }

  .react-contextmenu-item {
    span {
      margin-left: initial;
      margin-right: 0.5rem;
    }
  }

  .nav,
  .pagination {
    padding-right: 0;
  }

  .nav-tabs.separator-tabs .nav-link {
    margin-left: 1.5rem;
    margin-right: 0;
  }

  .heading-number {
    margin-right: 0;
    margin-left: 10px;
  }

  .card-top-buttons {
    right: initial;
    left: 0;
  }

  .react-tagsinput {
    padding-right: 5px;
    padding-left: 0;
  }

  .app-row {
    padding-right: initial;
    padding-left: $app-menu-width;

    @include respond-below(lg) {
      padding-left: 0;
    }
  }

  .custom-control-inline {
    margin-right: 0;
    margin-left: 1rem;
  }

  .form-check-inline {
    margin-left: 0.75rem;
    margin-right: 0;
  }

  .form-check-input {
    margin-right: 0;
  }

  .tooltip-label-right .invalid-feedback::before {
    left: initial;
    right: -5px;
    border-right: initial;
    border-left: solid 5px theme('colors.primary');
  }

  .tooltip-label-right .invalid-feedback::after {
    right: -4px;
    left: initial;
    border-left: solid 5px theme('colors.foreground-color');
    border-right: initial;
  }

  .tooltip-label-right .invalid-feedback {
    left: initial;
    right: 50px;
  }

  .error-l-0 .invalid-feedback {
    left: initial;
    right: 0;
  }

  .error-l-25 .invalid-feedback {
    left: initial;
    right: 25px;
  }

  .error-l-50 .invalid-feedback {
    left: initial;
    right: 50px;
  }

  .error-l-75 .invalid-feedback {
    left: initial;
    right: 75px;
  }

  .error-l-100 .invalid-feedback {
    left: initial;
    right: 100px;
  }

  .error-l-125 .invalid-feedback {
    left: initial;
    right: 125px;
  }

  .error-l-150 .invalid-feedback {
    left: initial;
    right: 150px;
  }

  .error-l-175 .invalid-feedback {
    left: initial;
    right: 175px;
  }

  .error-l-200 .invalid-feedback {
    left: initial;
    right: 200px;
  }

  .error-l-225 .invalid-feedback {
    left: initial;
    right: 225px;
  }

  .error-l-250 .invalid-feedback {
    left: initial;
    right: 250px;
  }

  .error-l-275 .invalid-feedback {
    left: initial;
    right: 275px;
  }

  .app-menu {
    left: 0;
    right: initial;

    @include respond-below(lg) {
      transform: translateX(-$app-menu-width);
    }

    &.shown {
      transform: translateX(0);
    }

    .app-menu-button {
      left: calc(#{$app-menu-width} - 2px);
      box-shadow: 4px 0 5px rgba(0, 0, 0, 0.04);
    }
  }

  .fc-toolbar.fc-header-toolbar .fc-prev-button {
    margin-right: 0;
  }

  .fc-toolbar.fc-header-toolbar .fc-next-button {
    margin-right: 5px;
  }

  .float-right {
    float: left !important;
  }

  .float-left {
    float: right !important;
  }

  @include respond-above(xl) {

    .float-xxl-left {
      float: right !important;
    }
    .float-xxl-right {
      float: left !important;
    }
  }

  @include respond-above(lg) {

    .float-xl-left {
      float: right !important;
    }
    .float-xl-right {
      float: left !important;
    }
  }

  @include respond-above(md) {

    .float-lg-left {
      float: right !important;
    }
    .float-lg-right {
      float: left !important;
    }
  }

  @include respond-above(sm) {

    .float-md-left {
      float: right !important;
    }
    .float-md-right {
      float: left !important;
    }
  }

  @include respond-above(xs) {

    .float-sm-left {
      float: right !important;
    }
    .float-sm-right {
      float: left !important;
    }
  }

  @include respond-above(xxs) {

    .float-xs-left {
      float: right !important;
    }
    .float-xs-right {
      float: left !important;
    }
  }

  .badge {
    &.badge-top-left {
      left: initial;
      right: -7px;
    }

    &.badge-top-left-2 {
      left: initial;
      right: -7px;
    }

    &.badge-top-right {
      left: -7px;
      right: initial;
    }

    &.badge-top-right-2 {
      left: -7px;
      right: initial;
    }
  }

  .r-0 {
    left: 0;
    right: initial;
  }

  .form-check {
    padding-left: 0;
    padding-right: 0;
  }

  .form-check-label {
    padding-right: 0;
  }

  .has-float-label label,
  .has-float-label > span:last-of-type {
    right: 0.75rem;
    left: initial;
  }

  .has-top-label label,
  .has-top-label > span:last-of-type {
    right: 0.75rem;
    left: initial;
  }

  .select2-selection__arrow {
    right: initial;
    left: 12px;
  }

  .nav-pills .nav-link {
    text-align: center;
  }

  .custom-switch .custom-switch-input + .custom-switch-btn:after {
    left: 0;
  }

  .custom-switch .custom-switch-input:checked + .custom-switch-btn:after {
    left: -28px;
  }

  .custom-switch-small .custom-switch-input:checked + .custom-switch-btn:after {
    left: -18px;
  }

  .bootstrap-tagsinput .tag {
    padding-right: 10px;
    padding-left: 20px;
    span {
      margin-left: 0px;
      position: relative;
      &:after {
        position: absolute;
        top: -2px;
        left: -15px;
      }
    }
  }

  .ps__rail-y {
    right: initial !important;
    left: 0 !important;
  }

  .scroll {
    margin-left: -15px;
    padding-left: 15px;
    margin-right: 0;
    padding-right: 0;
  }

  .modal {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 1.75rem;
      @include respond-below(xs) {
        padding: 1.5rem;
      }
    }
  }

  .select-from-library {
    .modal-body.scroll {
      margin-left: 0;
      .ps__rail-y {
        left: 10px !important;
      }
    }

    .sfl-item-container {
      .card-body {
        padding-right: 1.75rem !important;
        @include respond-below(xs) {
          padding: 1.5rem !important;
        }
      }

      .custom-control {
        padding-left: 0.25rem !important;
      }
    }
  }

  .sidebar .sub-menu .scroll .ps__thumb-y,
  .sidebar .main-menu .scroll .ps__thumb-y {
    right: 0;
  }

  .dropzone .dz-preview.dz-image-preview .dz-progress {
    right: 50%;
    left: initial;
    transform: translateX(50%);
  }

  .dropzone .dz-preview.dz-file-preview .remove,
  .dropzone .dz-preview.dz-image-preview .remove {
    right: initial;
    left: 5px;
  }

  .chart {
    direction: ltr;
    canvas {
      direction: rtl;
    }
  }

  .noUi-horizontal .noUi-origin {
    left: initial !important;
    right: initial !important;
  }

  .icon-button {
    text-align: center;
  }

  .ReactTable .rt-resizer {
    right: initial;
    left: -18px;
  }

  .rbc-header {
    text-align: center;

    &:last-of-type {
      border-left: initial;
    }

    &:first-of-type {
      border-left: 1px solid theme('colors.separator-color-l') !important;
    }
  }

  .rbc-day-bg {
    &:last-of-type {
      border-left: initial;
    }
    &:first-of-type {
      border-left: 1px solid theme('colors.separator-color-l') !important;
    }
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding-left: 30px !important;
    padding-right: 0 !important;
  }

  /* Sidebar */
  .sidebar {
    right: 0;
    left: initial;

    .main-menu {
      left: initial;
      right: 0;

      ul li {
        position: relative;

        &.active:after {
          content: " ";
          left: initial;
          right: 0;

          @include respond-below(sm) {
            right: 2px;
          }
        }
      }
    }

    .sub-menu {
      left: initial;
      right: $main-menu-width;
      border-right: 1px solid theme('colors.separator-color-l');
      @include depth(2);

      ul {
        li {
          margin-left: initial;
          margin-right: 30px;

          i {
            margin-right: initial;
            margin-left: 10px;
          }

          &.active:after {
            left: initial;
            right: -16px;
          }
        }
      }

      ul.third-level-menu {
        li {
          &.active:after {
            left: initial;
            right: -22px;
          }
        }
      }

      @include respond-below(xl) {
        left: initial;
        right: $main-menu-width-lg;
      }

      @include respond-below(lg) {
        left: initial;
        right: $main-menu-width-md;
      }

      @include respond-below(sm) {
        left: initial;
        right: $main-menu-width-xs;
      }
    }
  }

  /* Main */
  main {
    margin-right: $sub-menu-width + $main-menu-width + $main-margin;
    margin-left: $main-margin !important;
    transition: margin-right $menu-collapse-time;

    @include respond-below(xl) {
      margin-right: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
      margin-left: $main-margin-lg !important;
    }

    @include respond-below(lg) {
      margin-right: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
      margin-left: $main-margin-md !important;
    }

    @include respond-below(sm) {
      margin-right: $main-margin-xs !important;
      margin-left: $main-margin-xs !important;
    }

    @include respond-below(xs) {
      margin-bottom: 0;
    }
  }

  /*Navbar */
  .navbar {
    .navbar-right {
      text-align: left;
    }

    .menu-button-mobile {
      margin-right: 15px;
      margin-left: initial;
    }

    .menu-button {
      text-align: center;
    }

    .icon-menu-item {
      text-align: center;

      i {
        text-align: center;
      }

      span {
        text-align: center;
      }
    }

    .search {
      .search-icon {
        right: initial;
        left: 5px;
      }

      @include respond-below(sm) {
        margin-right: 0.6rem;
        margin-left: initial;

        input {
          display: none;
        }


        &.mobile-view {
          margin-left: initial;
          margin-right: 0px;

    
          input {
            display: block;
            width: 100%;
            height: 60px;
            padding-left: 15px;
          }

          span {
            left: 15px;
            right: initial;
          }
        }
      }
    }

    .navbar-right {
      text-align: left;

      .user {
        margin-left: $main-margin;
        margin-right: 0;

        @include respond-below(xl) {
          margin-left: $main-margin-lg;
          margin-right: 0;
        }

        @include respond-below(lg) {
          margin-left: $main-margin-md;
          margin-right: 0;
        }

        @include respond-below(sm) {
          margin-left: $main-margin-xs;
          margin-right: 0;
        }

        img {
          margin-right: 10px;
          margin-left: initial;
        }
      }

      .header-icons {
        margin-right: initial;
        margin-left: 0.5rem;
        @include respond-below(xs) {
          margin-left: 0;
        }
      }

      #notificationButton {
        .count {
          text-align: center;

          @include respond-below(xs) {
            left: -1px;
          }
        }
      }

      #userDropdown,
      #userDropdown * {
        text-align: left;
      }
    }
  }

  .heading-icon {
    margin-right: initial;
    margin-left: 5px;
  }

  .survey-app .answers input {
    padding-right: 0.75rem;
    padding-left: 70px;
  }

  .input-icons {
    left: 0;
    right: initial;
  }

  .simple-icon-arrow-left:before {
    content: "\e606";
  }

  .simple-icon-arrow-right:before {
    content: "\e605";
  }

  //Menu Config Main Menu Sub Menu
  .sub-hidden .sub-menu,
  .menu-sub-hidden .sub-menu,
  .menu-hidden .sub-menu {
    transform: translateX(-$sub-menu-width);

    @include respond-below(xl) {
      transform: translateX(-$sub-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX(-$sub-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX(-$sub-menu-width-xs);
    }
  }

  .main-hidden .main-menu,
  .menu-hidden .main-menu {
    transform: translateX($main-menu-width);

    @include respond-below(xl) {
      transform: translateX($main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX($main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX($main-menu-width-xs);
    }
  }

  .main-hidden.sub-hidden .sub-menu,
  .menu-hidden .sub-menu {
    transform: translateX($sub-menu-width + $main-menu-width);

    @include respond-below(xl) {
      transform: translateX($sub-menu-width-lg + $main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX($sub-menu-width-md + $main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX($sub-menu-width-xs + $main-menu-width-xs);
    }
  }

  .menu-main-hidden {
    .main-menu {
      width: 0;
    }

    .sub-menu {
      right: 0;
    }
  }

  //Mobile
  .menu-mobile {
    .main-menu {
      transform: translateX($main-menu-width-xs);
    }

    .sub-menu {
      transform: translateX($sub-menu-width-xs + $main-menu-width-xs + 50);
    }
  }

  //Show Temporary
  .main-show-temporary {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX($sub-menu-width);
    }

    @include respond-below(xl) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX($sub-menu-width-lg);
      }
    }

    @include respond-below(lg) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX($sub-menu-width-md);
      }
    }

    @include respond-below(sm) {
      .sub-menu {
        transform: translateX($sub-menu-width-xs);
      }
    }
  }

  .sub-show-temporary,
  .menu-mobile.sub-show-temporary,
  .menu-main-hidden.menu-mobile.main-show-temporary {
    .sub-menu {
      transform: translateX(0);
    }
  }

  /*Chat Input Container*/
  .chat-input-container {
    padding-right: $main-menu-width + $main-margin + $sub-menu-width;
    padding-left: $app-menu-width + $main-margin;

    @include respond-below(xl) {
      padding-right: $main-menu-width-lg + $main-margin-lg + $sub-menu-width-lg;
      padding-left: $app-menu-width + $main-margin-lg;
    }

    @include respond-below(lg) {
      padding-right: $main-menu-width-md + $main-margin-md + $sub-menu-width-md;
      padding-left: $main-margin-md;
    }

    @include respond-below(sm) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .menu-sub-hidden,
  .sub-hidden {
    .chat-input-container {
      padding-right: $main-menu-width + $main-margin;

      @include respond-below(xl) {
        padding-right: $main-menu-width-lg + $main-margin-lg;
      }

      @include respond-below(lg) {
        padding-right: $main-menu-width-md + $main-margin-md;
      }

      @include respond-below(sm) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .menu-main-hidden,
  .menu-hidden,
  .main-hidden {
    .chat-input-container {
      padding-right: $main-margin;

      @include respond-below(xl) {
        padding-right: $main-margin-lg;
      }

      @include respond-below(lg) {
        padding-right: $main-margin-md;
      }

      @include respond-below(sm) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  //Menu Config Main
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-right: $main-menu-width + $main-margin;
  }

  .main-hidden main,
  .menu-hidden main {
    margin-right: $main-margin;
  }

  .menu-main-hidden {
    main {
      margin-right: $main-margin + $sub-menu-width;
    }
    &.menu-hidden {
      main {
        margin-right: $main-margin;
      }
    }
  }

  @include respond-below(xl) {
    .sub-hidden main,
    .menu-sub-hidden main,
    .menu-hidden main {
      margin-right: $main-menu-width-lg + $main-margin-lg;
    }

    .main-hidden main,
    .menu-hidden main {
      margin-right: $main-margin-lg;
    }

    .menu-main-hidden {
      main {
        margin-right: $main-margin-lg + $sub-menu-width-lg;
      }
      &.menu-hidden {
        main {
          margin-right: $main-margin-lg;
        }
      }
    }
  }

  @include respond-below(lg) {
    .sub-hidden main,
    .menu-sub-hidden main,
    .menu-hidden main {
      margin-right: $main-menu-width-md + $main-margin-md;
    }

    .main-hidden main,
    .menu-hidden main {
      margin-right: $main-margin-md;
    }

    .menu-main-hidden {
      main {
        margin-right: $main-margin-md + $sub-menu-width-md;
      }
      &.menu-hidden {
        main {
          margin-right: $main-margin-md;
        }
      }
    }
  }

  .theme-colors {
    transform: translate(-280px, -50%);
    left: 0;
    right: initial;

    .theme-button {
      left: 278px;
      box-shadow: 3px 0 5px rgba(0, 0, 0, 0.04);
    }

    &.shown {
      transform: translate(0, -50%);
    }
  }

  // Rounded
  &.rounded {
    .app-menu {
      border-top-right-radius: $border-radius-rounded;
      border-bottom-right-radius: $border-radius-rounded;
    }

    .sub-menu {
      border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
    }

    .auth-card .image-side {
      border-top-left-radius: $border-radius-rounded;
      border-bottom-left-radius: $border-radius-rounded;
    }

    .card-img,
    .card-img-fluid,
    .card-img-overlay {
      border-radius: $border-radius-rounded;
    }

    .sub-hidden .main-menu,
    .menu-sub-hidden .main-menu {
     // border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
    }

    .sub-show-temporary .main-menu {
      border-radius: initial;
    }

    .card-img-left {
      border-top-right-radius: $border-radius-rounded !important;
      border-bottom-right-radius: $border-radius-rounded !important;
      border-top-left-radius: initial !important;
      border-bottom-left-radius: initial !important;
    }

    .card-img-right {
      border-top-left-radius: $border-radius-rounded !important;
      border-bottom-left-radius: $border-radius-rounded !important;
      border-top-right-radius: initial !important;
      border-bottom-right-radius: initial !important;
    }

    .card.auth-card {
      border-top-left-radius: $border-radius-rounded;
      border-bottom-left-radius: $border-radius-rounded;
      border-top-right-radius: $border-radius-rounded + 0.5rem;
      border-bottom-right-radius: $border-radius-rounded + 0.5rem;
    }

    .auth-card .image-side {
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
  }
}
