@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,400i,600,700");

@font-face {
	font-family: 'Gilmer';
	src: url('../../fonts/Gilmer/Gilmer Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gilmer';
	src: url('../../fonts/Gilmer/Gilmer Light.otf') format('opentype');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: 'Gilmer';
	src: url('../../fonts/Gilmer/Gilmer Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

:root, html {

  @screen sm {
    font-size: 14px;
  }

  @screen 2xl {
    font-size: 20px;
  }

  @screen xl {
    font-size: 18px;
  }

  @screen lg {
    font-size: 18px;
  }

  @screen md {
    font-size: 16px;
  }



  font-size: 16px;

}


h1, h2, h3, h4, h5 {
  font-family: "Poppins";
}

// h2 {
//   font-size: 2.143rem;
//   font-weight: 600;
//   @apply text-dark;
// }

// h3 {

//   font-size: 1.9rem!important;
//   @apply text-dark;
//   font-weight: 600;
  
//   &.small {
//     font-size: 1.5rem!important;
//     font-weight: 600;
//   }
  
// }

// h4 {
//  font-size: 1.15rem;
// }

// h5 {
//   font-size: 1.1rem;

// }

// h6 {
//   font-size: 0.8rem;
// }