@include respond-below(md) {
    .info-blocks {
        .card-text {
            height: 1.7rem;
        }
    }

    .property-location {
     
        &, .card-body {
            padding: 0;
        }

        margin-bottom: 5rem;
    }

    .property-view {
        .col-left {
            border-radius: 18px;
            margin-top: -1.5rem;
            background-color: #fff;
            padding-top: 2rem;
        }
    }

    .top-nav-ctn {
        position: fixed!important;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        margin-right: 0!important;
        margin-left: 0!important;

        button {
            margin-right: -1.5rem;
        }

        .col-12 {
            padding: 0!important;
        }

        svg {
            color: white;
        }
    }

    .features {
        flex-direction: column;

        table {
            margin: 0!important;
        }
    }
    
    .glide__slides > div {
        display: flex;
        align-items: center;
    }

    .pic-count {
        bottom: 1.8rem!important;
        left: 1rem!important;
    }
    
    .page-top-title {
        display: flex;
        justify-content: space-between;
    }

}

.react-share__ShareButton {
    @extend .btn;
}