#spash-video {
    min-width: 100%;
    min-height:  calc(100% - #{$navbar-height});
}

.app-ctn {
  position: relative;
}

.app-layout {
  min-height: calc(100vh - #{$navbar-height});
}

.ad-component {
  margin-left: $main-menu-width-lg;

  @include respond-below(sm) {
    margin-left: 0!important;
  }
}

main.page, main.map-page {
    // padding-top: $navbar-height;
    margin-right: 0;
    margin-left: $sub-menu-width + $main-menu-width;
    overflow-x: hidden;

    @include respond-below(xl) {
      padding-top: 0;
      margin-top: $navbar-height-md!important;
    }

    @include respond-below(sm) {
      margin-left: 0!important;
      margin-right: 0!important;
      
    }

    @include respond-below(lg) {
      margin-top: $navbar-height-md!important;
    }
}

main {
  @include respond-below(xl) {
    padding-top: 0.5rem;
    margin-top: $navbar-height-md!important;
  }
}

.splash-slide {
  @include respond-below(lg) {
    max-width: 100%!important;
  }

}

.map-wrapper {
  max-height: calc((100vh - 58px) - 70px);
  @include respond-below(md) {
    max-height: calc((100% - 56px) - 55px);
    min-height: -webkit-fill-available;
  }
}

.map-page {
  height: 100vh;
  overflow: hidden;
  margin-bottom: 0!important;
  display: flex;
  flex-direction: column;

  @include respond-above(xl) {
    .image-card-ctn {
      // max-height: 270px;
    }
  }

  @include respond-below(md) {
    max-height: calc((100% - 56px) - 55px);
  }

  .favourites {
    @include respond-above(lg) {

      > div {
        display: block!important;
        width: 50%!important;
      }

      #map, .map-page  {
        display: block!important;
        width: 100%;
      }
  
    }
  }

  @media (max-width: 500px) {
    .card {
      max-width: 100%;
    }
  }

  @media (min-width: 1000px) {
    .card {
      max-width: 500px;
    }
  }


  @media (min-width: 1400px) {
    .card {
      max-width: 270px;
    }
  }

  @media (min-width: 1500px) {
    .card {
      max-width: 290px;
    }
  }

  @media (min-width: 1600px)  {
    .card {
      max-width: 315px;
    }
  }

  @media (min-width: 1800px) {
    .card {
      max-width: 360px;
    }
  }

  @include respond-below(lg) {
    #map, #list {
      width: 100vw;
    }
    padding-bottom: 50px;

    #list {
      box-shadow: none;
    }

  }

  @include respond-below(sm) {
    #list {
      .property-card {
        width: 100%;
      }
    }
  }

  @include respond-above(lg) {
    .map-ctn {
      flex: 2;
    }
  
    .list-ctn {
      flex: 1.5;
      display: block!important;
    }
  }
}

.sub-hidden main.page,
.menu-sub-hidden main.page,
.menu-hidden main.page {
  margin-left: $main-menu-width;
}

.main-hidden main.page,
.menu-hidden main.page {
  margin-left: 0;
}

@include respond-below(xl) {
  .sub-hidden main.page,
  .menu-sub-hidden main.page,
  .menu-hidden main.page {
    margin-left: $main-menu-width-lg;
  }

  .main-hidden main.page,
  .menu-hidden main.page {
    margin-left: 0
  }
}

@include respond-below(lg) {
  .sub-hidden main.page,
  .menu-sub-hidden main.page,
  .menu-hidden main.page {
    margin-left: $main-menu-width-md;
  }

  .main-hidden main.page,
  .menu-hidden main.page {
    margin-left: 0;
  }
}

.subscribe-title {
  @include respond-below(sm) {
    text-align: center;
    padding-bottom: 1rem;

    h2 {
      font-size: 1.5rem;
    }
  }
}

  .landing-app {
    // background: url(/assets/img/mobile-image.jpg) left center no-repeat;
    padding: 50px 0 30px;
    background-size: contain!important;
    background-position: top left!important;
    background-repeat: no-repeat!important;
    height: 600px;

    @include respond-below(lg) {
      margin-top: 1rem;
      margin-bottom: 4rem!important;
      
      .landing-callout-text {
          margin-top: 6rem;
      }
    }

  }

  .landing-stats {

    @include respond-below(lg) {
      margin-top: 1rem;
      margin-bottom: 8rem!important;
      
      .landing-callout-text {
          order: 2;
          margin-top: 3rem;
      }
    }

    //background: url(/assets/img/find-properties.jpg) right center no-repeat;
    padding: 50px 0 30px;
    background-size: contain!important;
    background-position: top right!important;
    height: 600px;
  }   

.autocomplete-dropdown-container {
  position: absolute;
  background-color: white;
  z-index: 99;
  border: 1px solid theme('colors.separator-color');
  width: 100%;
 
  .suggestion-item, .suggestion-item--active {
    padding: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color:theme('colors.separator-color');
    }
  }
}

@include respond-below(lg) {
  .modal-dialog {
    max-width: 100%!important;
  }
}

.property-mobile-slider {
    position: absolute;
    bottom: 4rem;
    width: 100%;
}

.mobile-property-drawer {

  bottom: 55px!important;
  top: 50%!important;
  z-index: 1049!important;

  .slick-slide {
    
    > div {
      transition: all 0.05s ease-in-out;
    }

  }

  .MuiDrawer-paperAnchorBottom
   {
    background-color: transparent!important;
    box-shadow: none!important;
  }

  .MuiBackdrop-root {
    display: none!important;
  }
}

.animated {
  transition: all 1.5s cubic-bezier(0.190, 1.000, 0.220, 1.000);
} 


.MuiBottomNavigation-root {
  z-index: 1050!important;
}

@include respond-below(lg) {
  .map-search-ctn .modal-search {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    overflow-x: hidden;
    padding: 10px;
    padding-bottom: 56px;
    background-color: #fff;
  }
}


@include respond-below(lg) {

  .image-card-ctn {
    // max-height: 300px;
    overflow: hidden;
  }

  .search-section {
    p, a {
      font-size: 0.8rem!important;
    }
  }

  .landing-home {
    h1 {
      font-size: 1.8rem!important;
    }

  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom, .gm-control-active.gm-fullscreen-control {
    display: none;
  }

  .info-blocks {
    .lead{
      font-size: 1rem;
    }
  }

 .modal-body .glide__slide {
    display: flex;
    align-items: center;
    img {
      height: auto!important;
    }
  }

  .register-modal {

    background-color: theme('colors.bg-color')!important;

    .fa-arrow-left {
      color: theme('colors.darker')!important;
    }
  
    .modal-dialog {
      margin: 0!important;
      height: 100%!important;
      min-height: 100%!important;
      box-shadow: none;
      
    }
    .modal-content, .modal-body, .card {
      background-color: theme('colors.bg-color')!important;
    }
    .auth-card {
      > div {
        border-radius: 0!important;
      }
    }
  }

  .property-carousel {
    .glide-item {
      padding: 1.5rem;
    }
  }
}

.property-modal {
  z-index: 99999;

  .dark {
    .top-nav-ctn {
      background: transparent!important;
      svg {
        color: theme('colors.darker')!important;
      }
    }
  }
  
}

.map-search-ctn {
  width: 100%;
  padding: 10px;
}

.property-slider-ctn {
  .card {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px !important;
  }
}

span.link {
  cursor: pointer;
  &:hover {
    color: theme('colors.secondary');
  }
}

.fb_dialog  {

  display: none!important;

  iframe {
    @include respond-below(lg) {
      // top: initial!important;
      // bottom: 62px!important;
      // right: -1px!important;
      transform: scale(0);
      transition: all 0.5s ease-in-out;
      margin-bottom: 35px!important;
      margin-right: 0px!important;

      &.active {
        transform: scale(1);
      }
    }
  }

  @include respond-below(lg) {
    display: block!important;
  }
}

.fb_dialog {
  z-index: 99!important;
}

.fb-customerchat.fb_invisible_flow.fb_iframe_widget.fb_iframe_widget_fluid {
  iframe {
    bottom: 70px!important;
  } 
}

@include respond-above(md) {
 #search-bg {
   display: none;
 }
}

@include respond-below(md) {
  .MuiAutocomplete-popper.search-popover {
    min-width: 100vw;
    transform: translate3d(0, calc(#{$navbar-height-xs} - 3px), 0)!important;
  }

  .photo-uploader {
    width: 340px!important;
  }

  .application-modal {
    min-height: 97%!important;
  }

  .react-tel-input {
    input {
      height: 40px!important;
    }
  }

  .application-modal {
    .wizard-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
      background-color: #fff;
    }

    .modal-body {
      padding-bottom: 4rem!important;
    }
  }
 }

 .field.btn {

    background-color: transparent;
    width: 100%;
    border-radius: 0.1rem;
    font-size: 0.8rem;
    padding: 0.75rem;
    border: 1px solid theme('colors.border-color');
    text-align: left;
    color: #000;

    &:hover {
      border: 1px solid #000;
    }

    svg {
      font-size: 1rem;
      opacity: 0.8;
    }
 }

input[state="danger"] {
  border-color: $error-color!important;
}

button.error {
   border: 1px solid $error-color!important;
}

.h-300 {
  height: 300px;
}

.h-400 {
  height: 400px;
}