
/* 21.Forms */
.av-invalid .av-label,
.form-group.text-danger,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: theme('colors.darker') !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: theme('colors.separator-color');
  background: initial;
}

.invalid-feedback {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: theme('colors.darker');
  background: theme('colors.foreground-color');
  border: 1px solid theme('colors.primary');
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  @include depth(1);

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px theme('colors.primary');
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px theme('colors.foreground-color');
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.tooltip-right-bottom .invalid-feedback {
  left: initial;
  right: 0;
  transform: translateX(0);

  &::before,
  &::after {
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-bottom .invalid-feedback {
  left: 0;
  right: initial;
  transform: translateX(0);

  &::before,
  &::after {
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-center-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(-50%) translateY(50%);
  top: initial;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px theme('colors.primary');
    border-bottom: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px theme('colors.foreground-color');
    border-bottom: initial;
  }
}

.tooltip-right-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: initial;
  right: 0;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px theme('colors.primary');
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px theme('colors.foreground-color');
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: 0;
  right: initial;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px theme('colors.primary');
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px theme('colors.foreground-color');
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-label-right .invalid-feedback {
  transform: translateX(0) translateY(-50%);
  top: 16px;
  left: 50px;

  &::before {
    content: "";
    position: absolute;
    left: -5px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px theme('colors.primary');
    bottom: initial;
    top: 12px;
    width: 5px;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    left: -4px;
    right: initial;
    margin-left: 0;
    width: 5px;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px theme('colors.foreground-color');
    bottom: initial;
    top: 12px;
    width: 5px;
  }
}

.error-l-0 .invalid-feedback {
  left: 0px;
}

.error-l-25 .invalid-feedback {
  left: 25px;
}

.error-l-50 .invalid-feedback {
  left: 50px;
}

.error-l-75 .invalid-feedback {
  left: 75px;
}

.error-l-100 .invalid-feedback {
  left: 100px;
}

.error-l-125 .invalid-feedback {
  left: 125px;
}

.error-l-150 .invalid-feedback {
  left: 150px;
}

.error-l-175 .invalid-feedback {
  left: 175px;
}

.error-l-200 .invalid-feedback {
  left: 200px;
}

.error-l-225 .invalid-feedback {
  left: 225px;
}

.error-l-250 .invalid-feedback {
  left: 250px;
}

.error-l-275 .invalid-feedback {
  left: 275px;
}

.error-t-negative .invalid-feedback {
  top: -10px;
}

.form-inline {
  .form-group {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.form-check-label,
.custom-control-label {
  line-height: 24px !important;
}

.react-tagsinput {
  background-color: theme('colors.input-bg') !important;
  border: 1px solid theme('colors.separator-color') !important;
  outline: initial !important;
  box-shadow: initial !important;
}

.react-tagsinput--focused {
  border: 1px solid theme('colors.primary-muted') !important;
}

.react-tagsinput-tag {
  background: theme('colors.primary') !important;
  border-radius: 15px !important;
  padding: 0px 10px !important;
  margin-bottom: 0px !important;
  display: inline-block !important;
  font-size: 12px !important;
  color: theme('colors.foreground-color') !important;
  border: initial !important;
}

.form-control:focus {
  background: theme('colors.input-bg');
  color: theme('colors.darker');
}

.bootstrap-tagsinput {
  width: 100%;
}

.bootstrap-tagsinput input {
  padding: 0;
}

.form-control:focus {
  border-color: theme('colors.primary-muted');
}

select.form-control:not([size]):not([multiple]) {
  height: initial;
}


.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: theme('colors.darker-x-muted') !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='"+encodecolor(
      theme('colors.btn-text-l')
    )+"' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='"+encodecolor(
      theme('colors.btn-text-l')
    )+"' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='"+encodecolor(
      theme('colors.btn-text-l')
    )+"'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: initial;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
}

.custom-control-label::before {
  border: 1px solid theme('colors.muted');
  background: initial;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: theme('colors.primary');
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: theme('colors.primary');
  box-shadow: initial !important;
  border: 1px solid theme('colors.primary');
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: theme('colors.primary');
  box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: theme('colors.primary');
  box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
  outline: initial !important;
}


.check-all {
  padding-top: 0;

  label.custom-control-label {
    vertical-align: top;
    line-height: initial !important;
    &:before, &:after {
      border: initial !important;
      background: initial !important;
    }
  }

  span.custom-control-label {
    line-height: 1.1rem !important;
    &:before {
      top: 0 !important;
    }

    &.indeterminate {
      &:after {
        top: 0 !important;
      }
  
    }
  }

  .custom-control {
    margin-top: 12px;
  }

  .custom-control-input:checked ~ .custom-control-label span::after {
    top: 0 !important;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='"+encodecolor(
        theme('colors.btn-text-l')
      )+"' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }

}

.custom-control-label::before {
  top: 0.2rem;
}

.custom-control-label::after {
  top: 0.2rem;
}

.btn.rotate-icon-click i {
  transition: transform 0.5s;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid theme('colors.btn-text-l');
}

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid theme('colors.btn-text-l');
}

.btn-group-icon {
  line-height: 22px;
}

.autosuggest {
  position: relative;
}

.react-autosuggest__suggestions-container {
  border-radius: $border-radius;
  z-index: 20;
  box-shadow: initial;
  margin-top: -1px;
  background: theme('colors.foreground-color');
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: theme('colors.foreground-color') !important;
  color: theme('colors.darker') !important;

  &:hover,
  &:active {
    background: theme('colors.primary-l') !important;
    color: theme('colors.foreground-color') !important;
  }
}

.react-autosuggest__input--open {
  border: 1px solid theme('colors.primary-muted') !important;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid theme('colors.primary-muted');
  border-top: initial;
  border-width: 1px !important;
}

.react-select {
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
  background: theme('colors.foreground-color') !important;
}

.react-select .react-select__dropdown-indicator {
  color: theme('colors.gray');
}

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: theme('colors.darker');
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: theme('colors.primary') !important;
  }
}

.react-select__control {
  border-radius: $border-radius !important;
  border: 1px solid theme('colors.separator-color') !important;
  background: theme('colors.input-bg') !important;
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.react-select__option {
  background: theme('colors.foreground-color') !important;
  color: theme('colors.darker') !important;

  &:hover,
  &:active {
    background: theme('colors.primary') !important;
    color: theme('colors.btn-text-l') !important;
  }
}

.react-select__option--is-selected {
  background: theme('colors.primary') !important;
  color: theme('colors.btn-text-l') !important;
}

.react-select__control--is-focused {
  border-color: theme('colors.primary-muted') !important;
}

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid theme('colors.separator-color') !important;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: theme('colors.primary') !important;
}

.react-select .react-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid theme('colors.primary-muted') !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: theme('colors.foreground-color') !important;
  border-width: 1px !important;
}

.react-select__option—is-focused { background: theme('colors.primary') !important; color: theme('colors.btn-text-l') !important; }

.react-select__single-value {
  bottom: 0 !important;
  top: 50% !important;
  padding-top: 2px !important;
}

.react-datepicker {
  background-color: theme('colors.input-bg') !important;
  border: theme('colors.separator-color') !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  text-align: center;
}

.react-datepicker__input-container input:focus {
  border-color: theme('colors.primary-muted') !important;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker__input-container input {
  background-color: theme('colors.input-bg') !important;
  border: theme('colors.separator-color') !important;
  padding: 10px 26px 6px 12px!important;
  border: 1px solid #b0b0b0 !important;
  border-radius: 4px !important;
  width: 100% !important;
  outline: initial !important;
  height: 40px;

  &::placeholder {
    font-size: 1rem;
  }
}

.react-datepicker {
  border: 1px solid theme('colors.separator-color') !important;
  border-radius: $border-radius !important;
  font-family: "Karla", sans-serif;
}

.react-datepicker__header {
  background-color: theme('colors.input-bg') !important;
  border-bottom: initial !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px !important;
  height: 35px !important;
  line-height: 2.3rem !important;
  border-radius: 0 !important;
  margin: 0 !important;
  outline: initial !important;
}

.react-datepicker__day:hover {
  background: theme('colors.separator-color');
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: theme('colors.primary');
}

.react-datepicker__day--keyboard-selected {
  background: theme('colors.primary') !important;
  color: theme('colors.btn-text-l') !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: theme('colors.foreground-color') !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: theme('colors.separator-color') !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: theme('colors.darker') !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: theme('colors.darker') !important;
}

.react-datepicker__input-container input {
  color: theme('colors.darker') !important;
}

.react-datepicker__time-container {
  border-left: 1px solid theme('colors.separator-color') !important;
  width: 71px !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: theme('colors.input-bg') !important;
  color: theme('colors.darker') !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: theme('colors.btn-text-l') !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: theme('colors.foreground-color') !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: theme('colors.separator-color') !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: theme('colors.bg-color') !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  text-align: center;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: theme('colors.primary') !important;
}

.react-datepicker__triangle {
  left: 30px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: theme('colors.primary') !important;
  border-radius: $border-radius !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: theme('colors.separator-color') !important;
}

.react-datepicker.embedded {
  border: initial !important;
  width: 100% !important;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 14.28% !important;
  }

  .react-datepicker__month-container {
    width: 100% !important;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 30px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: theme('colors.primary') !important;
}

.react-datepicker__day--today {
  font-weight: 400 !important;
  background: theme('colors.separator-color');
  color: theme('colors.btn-text-l') !important;
}

.dropzone {
  min-height: 115px !important;
  border: 1px solid theme('colors.separator-color') !important;
  background: theme('colors.input-bg') !important;
  padding: 10px 10px !important;
  border-radius: $border-radius !important;
  color: theme('colors.darker') !important;
  height: auto !important;

  .img-thumbnail {
    height: 58px !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid theme('colors.separator-color') !important;
  border-radius: $border-radius !important;
  background: theme('colors.foreground-color') !important;
  color: theme('colors.darker') !important;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: theme('colors.primary');
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: theme('colors.primary') !important;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: theme('colors.darker') !important;
  }

  .dz-error-mark {
    color: theme('colors.btn-text-l') !important;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:"+encodecolor(
          theme('colors.primary')
        )+";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:"+encodecolor(
          theme('colors.foreground-color')
        )+";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:"+encodecolor(theme('colors.foreground-color'))+";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-success-mark {
    color: theme('colors.btn-text-l');
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:"+encodecolor(
          theme('colors.primary')
        )+";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:"+encodecolor(
          theme('colors.foreground-color')
        )+";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
      background: theme('colors.primary') !important;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color !important;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color !important;
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
    color: theme('colors.primary') !important;
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}

.custom-switch {
  display: block !important;
  background: theme('colors.separator-color') !important;
  width: 58px !important;
  height: 27px !important;
  border: 1px solid theme('colors.separator-color') !important;

  &:after {
    width: 18px !important;
    height: 18px !important;
    top: 3px !important;
    margin-left: 3px !important;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: theme('colors.foreground-color') !important;
  }
  &.rc-switch:after {
    box-shadow: none !important;
  }

  &.rc-switch-checked:after {
    left: 30px !important;
    box-shadow: none !important;
  }

  &.rc-switch-checked.custom-switch-primary {
    background: theme('colors.primary') !important;
    border: 1px solid theme('colors.primary') !important;
  }

  &.rc-switch-checked.custom-switch-secondary {
    background: theme('colors.secondary') !important;
    border: 1px solid theme('colors.secondary') !important;
  }

  &.custom-switch-primary-inverse {
    border: 1px solid theme('colors.separator-color') !important;
  }

  &.rc-switch-checked.custom-switch-primary-inverse {
    background: theme('colors.input-bg') !important;
    border: 1px solid theme('colors.primary') !important;

    &:after {
      background: theme('colors.primary') !important;
    }
  }

  &.custom-switch-secondary-inverse {
    border: 1px solid theme('colors.separator-color') !important;
  }

  &.rc-switch-checked.custom-switch-secondary-inverse {
    background: theme('colors.input-bg') !important;
    border: 1px solid theme('colors.secondary') !important;

    &:after {
      background: theme('colors.secondary') !important;
    }
  }
}

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: theme('colors.separator-color') !important;
}

.custom-switch.custom-switch-primary .custom-switch-input:checked + .custom-switch-btn {
  background: theme('colors.primary') !important;
  border: 1px solid theme('colors.primary') !important;
}

.custom-switch.custom-switch-secondary .custom-switch-input:checked + .custom-switch-btn {
  background: theme('colors.secondary') !important;
  border: 1px solid theme('colors.secondary') !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input + .custom-switch-btn {
  border: 1px solid theme('colors.separator-color') !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked + .custom-switch-btn {
  background: theme('colors.input-bg') !important;
  border: 1px solid theme('colors.primary') !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked + .custom-switch-btn:after {
  background: theme('colors.primary') !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input + .custom-switch-btn {
  border: 1px solid theme('colors.separator-color') !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked + .custom-switch-btn {
  background: theme('colors.input-bg') !important;
  border: 1px solid theme('colors.secondary') !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked + .custom-switch-btn:after {
  background: theme('colors.secondary') !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: theme('colors.input-bg') !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: theme('colors.separator-color') !important;
}

.rc-switch.custom-switch.custom-switch-small {
  width: 42px !important;
  height: 23px !important;
}

.rc-switch.custom-switch.custom-switch-small:after {
  width: 14px !important;
  height: 14px !important;
  left: 1px !important;
  top: 3px !important;
}

.rc-switch-checked.custom-switch.custom-switch-small:after {
  left: 18px !important;
}

.input-group-text {
  border-radius: $border-radius;
  background-color: theme('colors.input-bg');
  border-color: theme('colors.separator-color');
  color: theme('colors.darker');
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-group {
  position: relative;

  &.round {
    .form-control, .rec-select .MuiSelect-root, .react-datepicker__input-container input {
      border-radius: 50px!important;
      font-size: 0.8rem;
      padding: 1rem 1.4rem!important;
    }

    .react-datepicker__input-container input {
      font-size: 0.8rem;
      height: initial;
    }

    label {
      margin-left: 1.1rem;
    }
  }

}

.form-control {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem;
  line-height: 1.5;
  border: 1px solid theme('colors.separator-color');
  background: theme('colors.input-bg');
  color: theme('colors.darker');
  height: initial;
}

@keyframes autofill {
  to {
    color: theme('colors.darker');
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: theme('colors.darker') !important;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: $border-radius;
}

.custom-select {
  border-radius: $border-radius;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-select {
  height: calc(2.5rem + 2px);
}

.custom-select:focus {
  border-color: theme('colors.primary');
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: theme('colors.primary-muted');
}

.custom-file-label::after {
  background: theme('colors.foreground-color');
  color: theme('colors.darker');
  border-color: theme('colors.separator-color');
}

.custom-file-input {
  box-shadow: initial !important;
}

.custom-file-label {
  background: theme('colors.foreground-color');
  border-color: theme('colors.separator-color');
}

.custom-file-label {
  box-shadow: initial !important;
  border-radius: $border-radius;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.custom-file {
  height: calc(2.5rem + 2px);
}

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: theme('colors.primary');
}

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.jumbotron {
  background: initial;
}

label, .placeholder {
  color: theme('colors.dark');
}