
/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid theme('colors.separator-color');
}

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  cursor: pointer;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: initial;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: theme('colors.primary');
  border-radius: 10px;
  position: absolute;
  width: calc(100% - 1rem);
  height: 5px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: theme('colors.primary');
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset;
  transform: initial;
}

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: theme('colors.gray');
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: theme('colors.primary');
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: theme('colors.primary');
}