/* 08.Carousel */
.glide__slides {

  & > * {
    white-space: initial;
  }
}

.glide-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

.slider-nav {
  text-align: center;

  .left-arrow {
    left: 0.5rem;
  }
  .right-arrow {
    right: 0.5rem;
  }
  .left-arrow,
  .right-arrow {
    transition: all 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
    opacity: 0;
    font-size: 16px;
    color: theme('colors.primary');
    display: inline-block;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.8);
    margin: 0 5px;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transform: scale(1);

    &:hover {
      transform: scale(1.05);
    }
  }

  .slider-dot-container {
    display: inline-block;
  }

  .btn {
    &:hover,
    &:focus,
    &:active {
      text-decoration: initial;
    }
  }
}

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: theme('colors.separator-color');
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;

  &.glide__bullet--active {
    background: theme('colors.primary');
  }
}

.glide {
  &:hover {
    .left-arrow,
    .right-arrow {
      opacity: 1;
    }
  }
}

.glide__slides .card .card-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

}

.glide__slides .card .w-50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}